<template>
  <div class="d-flex email-address gap-4">
    <el-form-item class="mb-0 w-100" :error="customError" :label="emailTitle" :prop="`emails[${emailCount}]`">
      <el-input :value="email" @input="updateValue" />
      <template #error="{ error }">
        <div class="validation-error-message">{{ error }}</div>
      </template>
    </el-form-item>

    <Button :disabled="disableRemove" class="delete-button" type="icon" @click="removeEmail">
      <DeleteIcon />
    </Button>
  </div>
</template>

<script>
import { Button } from '@/modules/core';
import { DeleteIcon } from '@/assets/icons';

export default {
  name: 'ContactSetEmailField',
  components: { Button, DeleteIcon },
  props: {
    emailTitle: { type: String, required: true },
    email: { type: String, required: true },
    emailCount: { type: Number, required: true },
    disableRemove: { type: Boolean, required: false, default: false },
    customError: { type: String, required: false, default: null },
  },
  emits: ['updated', 'remove'],
  methods: {
    updateValue(value) {
      this.$emit('updated', value);
    },
    removeEmail() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.email-address {
  .compensate-header {
    margin-top: 20px;
  }

  .delete-button {
    margin-top: 28px;
    color: #71767d;

    &[disabled] {
      color: #c4c6cf;
    }
  }

  .validation-error-message {
    line-height: 20px;
    color: $error;
  }
}
</style>
