<template>
  <div class="d-flex flex-column gap-4">
    <el-form-item :label="$t('contactsSetModal.details.type')" prop="type" class="mb-0">
      <el-select :value="type" class="w-100" @change="(value) => updateValue('type', value)">
        <el-option
          key="typeContact"
          :label="$t('contactsSetModal.details.typeSelect.contact')"
          :value="CONTACT_TYPE.CONTACT"
        />
        <el-option
          key="typeOffice"
          :label="$t('contactsSetModal.details.typeSelect.office')"
          :value="CONTACT_TYPE.OFFICE"
        />
      </el-select>
    </el-form-item>

    <div v-if="type === CONTACT_TYPE.OFFICE">
      <el-form-item :label="$t('contactsSetModal.details.officeName')" prop="officeName">
        <el-input :value="officeName" @input="(value) => updateValue('officeName', value)" />
        <template #error="{ error }">
          <div class="validation-error-message">{{ error }}</div>
        </template>
      </el-form-item>
    </div>

    <div v-else-if="type === CONTACT_TYPE.CONTACT" class="d-flex justify-content-between gap-4">
      <el-form-item :label="$t('contactsSetModal.details.firstname')" prop="firstName" class="mb-0">
        <el-input :value="firstName" @input="(value) => updateValue('firstName', value)" />
        <template #error="{ error }">
          <div class="validation-error-message">{{ error }}</div>
        </template>
      </el-form-item>
      <el-form-item :label="$t('contactsSetModal.details.lastname')" prop="lastName" class="mb-0">
        <el-input :value="lastName" @input="(value) => updateValue('lastName', value)" />
      </el-form-item>
    </div>

    <div class="d-flex flex-column gap-2 align-items-start">
      <div v-for="(_, index) in phones" :key="`phone-${index}`" class="mb-0 w-100">
        <PhoneField
          :key="`phone-field-${index}`"
          :phone-title="$t('contactsSetModal.details.phone', { count: index + 1 })"
          :extension-title="$t('contactsSetModal.details.extension')"
          :phone="phones[index]"
          :disable-remove="phones.length === 1"
          :phone-count="index"
          :custom-error="customErrors?.phones[index]"
          @updated="(data) => onPhoneUpdate(index, data)"
          @remove="() => onPhoneRemove(index)"
        ></PhoneField>
      </div>
      <el-button v-if="phones.length < 3" class="p-0" type="text" @click="addPhone">
        {{ phones.length ? $t('contactsSetModal.details.additionalPhone') : $t('contactsSetModal.details.addPhone') }}
      </el-button>
    </div>

    <div class="d-flex flex-column gap-2 align-items-start">
      <div v-for="(_, index) in emails" :key="`email-${index}`" class="mb-0 w-100">
        <EmailField
          :key="`email-field-${index}`"
          :email="emails[index]"
          :email-title="$t('contactsSetModal.details.email', { count: index + 1 })"
          :email-count="index"
          :disable-remove="emails.length === 1"
          :custom-error="customErrors?.emails[index]"
          @updated="(data) => onEmailUpdate(index, data)"
          @remove="() => onEmailRemove(index)"
        ></EmailField>
      </div>
      <el-button v-if="emails.length < 3" type="text" class="p-0" @click="addEmail">
        {{ emails.length ? $t('contactsSetModal.details.additionalEmail') : $t('contactsSetModal.details.addEmail') }}
      </el-button>
    </div>

    <el-form-item
      v-if="type === CONTACT_TYPE.CONTACT"
      :label="$t('contactsSetModal.details.gender')"
      prop="gender"
      class="d-flex flex-column align-items-start mb-0"
    >
      <el-radio-group
        :value="gender"
        size="mini"
        class="d-flex w-100 pt-2"
        @input="(value) => updateValue('gender', value)"
      >
        <el-radio label="male">{{ $t('contactsSetModal.details.genderSelect.male') }}</el-radio>
        <el-radio label="female">{{ $t('contactsSetModal.details.genderSelect.female') }}</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item :label="$t('contactsSetModal.details.note')" prop="note" class="mb-0">
      <el-input :value="note" type="textarea" resize="none" @input="(value) => updateValue('note', value)"> </el-input>
    </el-form-item>
  </div>
</template>

<script>
import PhoneField, { COUNTRY_CODE } from './fields/phoneField.vue';
import EmailField from './fields/emailField.vue';

export const INITIAL_PHONE = {
  countryCode: COUNTRY_CODE.IL,
  number: '',
  extension: '',
};
export const INITIAL_EMAIL = '';

export const CONTACT_TYPE = {
  OFFICE: 'office',
  CONTACT: 'contact',
};

export default {
  name: 'ContactSetInfo',
  components: { PhoneField, EmailField },
  props: {
    firstName: { type: String, required: false, default: null },
    lastName: { type: String, required: false, default: null },
    officeName: { type: String, required: false, default: null },
    type: { type: String, required: true },
    phones: { type: Array, required: true },
    emails: { type: Array, required: true },
    gender: { type: String, required: false, default: null },
    note: { type: String, required: false, default: null },
    customErrors: { type: Object, required: false, default: () => ({}) },
  },
  emits: ['updated', 'phoneAdd', 'phoneUpdate', 'phoneRemove', 'emailAdd', 'emailUpdate', 'emailRemove'],
  data() {
    return {
      CONTACT_TYPE,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('updated', { field, value });
    },
    addPhone() {
      if (this.phones.length < 3) {
        this.$emit('phoneAdd');
      }
    },
    onPhoneUpdate(index, newData) {
      if (index >= 0 && index < this.phones.length) {
        this.$emit('phoneUpdate', { index, newData });
      }
    },
    onPhoneRemove(index) {
      if (index >= 0 && index < this.phones.length) {
        this.$emit('phoneRemove', index);
      }
    },
    addEmail() {
      if (this.emails.length < 3) {
        this.$emit('emailAdd');
      }
    },
    onEmailUpdate(index, newData) {
      if (index >= 0 && index < this.emails.length) {
        this.$emit('emailUpdate', { index, newData });
      }
    },
    onEmailRemove(index) {
      if (index >= 0 && index < this.emails.length) {
        this.$emit('emailRemove', index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.validation-error-message {
  line-height: 20px;
  color: $error;
}
:deep(textarea.el-textarea__inner) {
  height: 64px;
}

:deep(label.el-form-item__label) {
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
}
</style>
