<template>
  <div class="d-flex phone-number">
    <el-form-item :error="customError" :label="phoneTitle" :prop="`phones[${phoneCount}].number`" class="mb-0">
      <el-input type="tel" :value="phone.number" @input="(value) => updateValue('number', value)" />
      <template #error="{ error }">
        <div class="validation-error-message">{{ error }}</div>
      </template>
    </el-form-item>
    <el-form-item :prop="`phone-countryCode-${phoneCount}`" class="d-flex align-items-center mb-0 flag-selector">
      <el-dropdown
        trigger="click"
        class="d-flex align-items-center"
        @command="(countryCode) => updateValue('countryCode', countryCode)"
      >
        <div v-if="phone.countryCode === 'IL'">
          <Flag_IL />
        </div>
        <div v-else-if="phone.countryCode === 'US'">
          <Flag_US />
        </div>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="COUNTRY_CODE.IL">
            <Button type="icon"> <Flag_IL /> </Button>
          </el-dropdown-item>
          <el-dropdown-item :command="COUNTRY_CODE.US">
            <Button type="icon"> <Flag_US /> </Button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-form-item>
    <el-form-item :label="extensionTitle" :prop="`phone-extension-${phoneCount}`" class="extension mb-0">
      <el-input :value="phone.extension" @input="(value) => updateValue('extension', value)" />
    </el-form-item>

    <Button :disabled="disableRemove" class="delete-button" type="icon" @click="removePhone">
      <DeleteIcon />
    </Button>
  </div>
</template>

<script>
import { Button } from '@/modules/core';
import { DeleteIcon, Flag_IL, Flag_US } from '@/assets/icons';

export const COUNTRY_CODE = {
  IL: 'IL',
  US: 'US',
};

export default {
  name: 'ContactSetPhoneField',
  components: { Button, DeleteIcon, Flag_IL, Flag_US },
  props: {
    phoneTitle: { type: String, required: true },
    extensionTitle: { type: String, required: true },
    phoneCount: { type: Number, required: true },
    disableRemove: { type: Boolean, required: false, default: false },
    customError: { type: String, required: false, default: null },
    phone: {
      type: Object,
      default: () => ({
        number: '',
        countryCode: '',
        extension: '',
      }),
    },
  },
  emits: ['updated', 'remove'],
  data() {
    return {
      COUNTRY_CODE,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('updated', { ...this.phone, ...{ [field]: value } });
    },
    removePhone() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.compensate-header {
  margin-top: 20px;
}

.delete-button {
  margin-top: 28px;
  color: #71767d;
}

.flag-selector {
  border: 1px solid $gray-outline;
  border-radius: 4px;
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
  border-inline-start: 0px;
  height: 32px;
  padding: 0px 5px;

  [dir='ltr'] & {
    margin-top: 25.5px;
  }
  [dir='rtl'] & {
    margin-top: 24px;
  }

  ן i {
    margin: 0;
    margin-inline-start: 5px;
  }

  svg {
    border-radius: 3px;
  }
}

.phone-number {
  min-height: 64px;

  & > :nth-child(1) {
    :deep(.el-input__inner) {
      [dir='ltr'] & {
        border-end-end-radius: 0px;
        border-start-end-radius: 0px;
      }

      [dir='rtl'] & {
        border-end-start-radius: 0px;
        border-start-start-radius: 0px;
      }
    }
  }

  & > :nth-child(n + 3) {
    margin-inline-start: 1rem;
  }
}

.extension {
  width: 60px;
}

.validation-error-message {
  line-height: 20px;
  color: $error;
  width: 215px;
}
</style>
