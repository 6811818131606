<template>
  <div class="workplace-container" @click="onSelect">
    <div class="workplace-actions">
      <el-dropdown
        class="d-flex justify-content-center"
        trigger="click"
        placement="bottom-start"
        @command="handleAction"
      >
        <Button type="icon" class="p-1 actions-btn text-typography-primary">
          <KebabIcon />
        </Button>

        <el-dropdown-menu>
          <div class="d-flex flex-column">
            <el-dropdown-item :command="ACTIONS.WORKPLACE_REMOVE">
              <div
                class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
              >
                <TrashCanIcon />
                <p>
                  {{ $t('contactsSetModal.workplace.actions.remove') }}
                </p>
              </div>
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="d-flex flex-column gap-4">
      <el-radio :value="selectedWorkplace" :label="workplaceCount" @change="onSelect">{{
        $t('contactsSetModal.workplace.workplaceCount', { count: workplaceCount })
      }}</el-radio>

      <el-form-item
        :label="$t('contactsSetModal.workplace.selectWorkplace')"
        :prop="`workplace-${workplaceCount}`"
        :rules="{ validator: validateWorkplace, trigger: ['blur', 'change'], required: true }"
        class="mb-0"
      >
        <template #error="{ error }">
          <div class="validation-error-message">{{ error }}</div>
        </template>
        <SearchBusinessFilter
          :title="$t('contactsSetModal.workplace.select')"
          :selected="workplace.id"
          :clearable="false"
          :selected-works-at-business="selectedWorksAtBusiness"
          @on-select="(business) => updateBusiness(business)"
        ></SearchBusinessFilter>
      </el-form-item>

      <el-form-item
        :label="$t('contactsSetModal.workplace.role')"
        :prop="`workplace-role-${workplaceCount}`"
        class="mb-0"
      >
        <el-input :value="workplace.role" @input="(value) => updateValue('role', value)"> </el-input>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { KebabIcon, TrashCanIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import SearchBusinessFilter from '../filters/searchBusinessFilter.vue';

const ACTIONS = {
  WORKPLACE_REMOVE: 'remove',
};

export default {
  name: 'ContactSetWorkplaceField',
  components: { SearchBusinessFilter, Button, KebabIcon, TrashCanIcon },
  props: {
    workplaceCount: { type: Number, required: true },
    selectedWorkplace: { type: Number, required: true },
    workplace: { type: Object, required: true },
    selectedWorksAtBusiness: { type: Array, required: false, default: () => [] },
  },
  emits: ['selected', 'updated', 'remove'],
  data() {
    return {
      ACTIONS,
    };
  },
  methods: {
    validateWorkplace(rule, value, callback) {
      if (!this.workplace?.id) callback(new Error(this.$t('contactsSetModal.validationErrors.worksAt.required')));
      callback();
    },
    onSelect() {
      this.$emit('selected', this.workplaceCount);
    },
    updateBusiness(business) {
      this.$emit('updated', {
        ...this.workplace,
        id: business.id,
        name: business.name,
        type: business.type,
        worksWith: [],
      });
    },
    updateValue(field, value) {
      this.$emit('updated', { ...this.workplace, ...{ [field]: value } });
    },
    handleAction(command) {
      switch (command) {
        case ACTIONS.WORKPLACE_REMOVE:
          this.$emit('remove');
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.workplace-container {
  border: 1px solid $gray-outline;
  border-radius: 4px;
  position: relative;
  padding: 16px;

  .workplace-actions {
    position: absolute;
    margin: 0px 16px;
    inset-inline-end: 0;
  }

  .disabled-layover.active {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

// extend dropdown search field to full width
:deep(.search-business-filter) {
  width: 100%;
  & > div {
    width: 100%;
    word-break: normal;
  }

  // on selected - imitate disabled
  &.selected {
    background-color: $gray;
  }
}

// remove dropdown search title when value exists
:deep(.filter.has-value > div:first-child) {
  display: none;
}

.validation-error-message {
  line-height: 20px;
  color: $error;
}
</style>
