<template>
  <div v-loading="worksAtBusinessLoading" class="work-with-container h-100">
    <div v-if="worksAt?.name" class="title mb-1">
      {{ $t('contactsSetModal.worksWith.title', { worksAt: worksAt.name }) }}
    </div>
    <div v-else class="title mb-1">
      {{ $t('contactsSetModal.worksWith.titleEmpty') }}
    </div>
    <div v-if="worksAt?.name && worksAtType === BUSINESS_TYPES.supplier" class="body h-100">
      <SearchBusinessSelect
        :title="$t('contactsSetModal.worksWith.addBusiness')"
        :selected="worksAt?.worksWith"
        @on-select="onSelect"
      ></SearchBusinessSelect>

      <div v-for="(business, index) in worksAt?.worksWith" :key="`worksWith-list-item-${index}`" class="mb-0 w-100">
        <div class="d-flex align-items-center justify-content-between gap-4">
          <div class="work-with-item">{{ business.name }}</div>
          <Button class="work-with-item-remove" type="icon" @click="() => onSelect(business)">
            <DeleteIcon />
          </Button>
        </div>
        <el-divider />
      </div>
    </div>
    <div v-else class="body d-flex flex-column h-100">
      <div class="add-business-selector disabled">
        {{ $t('contactsSetModal.worksWith.addBusiness') }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </div>
      <div class="add-business-body disabled">
        {{
          worksAtType === BUSINESS_TYPES.restaurant
            ? $t('contactsSetModal.worksWith.worksAtRestaurant')
            : $t('contactsSetModal.worksWith.worksAtNotSelected')
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { Button } from '@/modules/core';
import { DeleteIcon } from '@/assets/icons';
import { BUSINESS_TYPES } from '@/modules/purchase-management/tools/constants';

import { useBusinessById } from '../composition/businesses';
import SearchBusinessSelect from './filters/searchBusinessSelect.vue';

export default {
  name: 'ContactSetWorkWith',
  components: { SearchBusinessSelect, Button, DeleteIcon },
  props: { worksAt: { type: Object, required: false, default: () => ({}) } },
  emits: ['updated'],
  setup(props) {
    const { business, loading } = useBusinessById(
      computed(() => ({ businessId: !props.worksAt.type ? props.worksAt?.id : null }))
    );

    const worksAtType = computed(() => props.worksAt?.type || business.value?.type);

    return {
      worksAtType,
      worksAtBusinessLoading: worksAtType.value ? false : loading,
    };
  },
  data() {
    return {
      BUSINESS_TYPES,
    };
  },
  methods: {
    onSelect(business) {
      this.$emit('updated', business);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.work-with-container {
  display: flex;
  flex-direction: column;

  .title {
    height: auto;
    word-break: normal;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
  }
  .body {
    flex: 1;
    border: 1px solid $gray-outline;
    border-radius: 4px;

    .work-with-item {
      height: 38px;
      padding: 12px;
      line-height: 100%;
    }

    .work-with-item-remove {
      margin: 0px 12px;
      color: #71767d;
    }

    .add-business-selector.disabled {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 31px;
      padding: 0px 8px;
      background-color: $light-gray;
      border-bottom: 1px solid $gray-outline;
    }

    .add-business-body.disabled {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 0px 44px;
      text-align: center;
      background-color: $light-gray;
      word-break: normal;
    }

    :deep(.search-business-select) {
      width: calc(100% + 2px);
      margin: -1px -1px 1px -1px;
      & > div {
        width: 100%;
        border-end-end-radius: 0px;
        border-end-start-radius: 0px;
      }
    }
  }

  .el-divider {
    margin: 0px;
  }
}
</style>
