import { render, staticRenderFns } from "./contactSetInfo.vue?vue&type=template&id=6878b203&scoped=true"
import script from "./contactSetInfo.vue?vue&type=script&lang=js"
export * from "./contactSetInfo.vue?vue&type=script&lang=js"
import style0 from "./contactSetInfo.vue?vue&type=style&index=0&id=6878b203&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6878b203",
  null
  
)

export default component.exports