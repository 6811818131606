<template>
  <div>
    <div class="title">{{ $t('contactsSetModal.workplace.title') }}</div>
    <div class="d-flex flex-column gap-2 align-items-start">
      <div v-for="(_, index) in worksAt" :key="`workplace-${index}`" class="mb-0 w-100">
        <ContactSetWorkplaceField
          :key="`work-field-${index}`"
          :workplace-count="index + 1"
          :selected-workplace="workplaceActiveIndex"
          :workplace="worksAt[index]"
          :selected-works-at-business="selectedWorksAtBusiness"
          @selected="onWorkplaceSelected"
          @updated="(data) => onWorkplaceUpdated(index, data)"
          @remove="() => onWorkplaceRemove(index)"
        ></ContactSetWorkplaceField>
      </div>
      <el-button v-if="worksAt" class="p-0" type="text" @click="onAddWorkplace">
        {{ $t('contactsSetModal.workplace.additionalWorkplace') }}</el-button
      >
    </div>
  </div>
</template>

<script>
import ContactSetWorkplaceField from './fields/workplaceField.vue';

export default {
  name: 'ContactSetWorkplaces',
  components: { ContactSetWorkplaceField },
  props: {
    worksAt: { type: Array, required: true },
    workplaceActiveIndex: { type: Number, required: true },
    selectedWorksAtBusiness: { type: Array, required: false, default: () => [] },
  },
  emits: ['selected', 'updated', 'added', 'removed'],
  methods: {
    onWorkplaceSelected(workplaceIndex) {
      this.$emit('selected', workplaceIndex);
    },
    onWorkplaceUpdated(index, newData) {
      if (index >= 0 && index < this.worksAt.length) {
        this.$emit('updated', { index, newData });
      }
    },
    onAddWorkplace() {
      this.$emit('added');
    },
    onWorkplaceRemove(index) {
      if (index >= 0 && index < this.worksAt.length) {
        this.$emit('removed', index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 32px;
  font-weight: 550;
}

:deep(label.el-form-item__label) {
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
}
</style>
